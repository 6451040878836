.faqs {
  padding: 80px 0 150px;
  @media all and (max-width: 1024px) {
    padding: 10px 0 80px;
  }

  &_wrapper {
    display: flex;
    align-items: flex-start;
    @media all and (max-width: 576px) {
      flex-direction: column;
    }
  }

  &_title {
    position: relative;
    width: 50%;
    box-sizing: border-box;
    @media all and (max-width: 1024px) {
      width: auto;
      margin-right: 60px;
    }

    @media all and (max-width: 576px) {
      margin: 0 0 140px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 10%;
      left: 30%;
      transform: translateX(-50%);
      width: 193px;
      height: 185px;
      background: rgba(235, 0, 27, 0.40);
      filter: blur(50px);
      z-index: -1;
      @media all and (max-width: 1024px) {
        width: 100px;
        height: 100px;
      }
    }

    img {
      position: absolute;
      top: 40%;
      left: 10%;
      max-width: 182px;
      width: 100%;
      display: block;
      height: auto;
      @media all and (max-width: 1024px) {
        max-width: 94px;
      }
    }

    &_title {
      color: #FFF;
      text-transform: uppercase;
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: $Plex-400;
      font-size: 136px;
      font-style: normal;
      font-weight: 400;
      line-height: 1; /* 26.471% */
      margin: 0;
      @media all and (max-width: 1024px) {
        font-size: 56px;
      }
    }
  }

  &_list {
    width: 50%;
    @media all and (max-width: 1024px) {
      width: auto;
    }

  }
}