.blog_section {
  box-sizing: border-box;
  padding: 260px 0 0;

  &_title {
    color: $white_text_color;
    font-family: $Plex-400;
    font-size: 64px;
    font-style: normal;
    text-align: center;
    font-weight: 400;
    line-height: 64px;
    margin: 0 0 80px;
    @media all and (max-width: 1200px) {
      font-size: 40px;
      line-height: 52px;
    }
    @media all and (max-width: 1024px) {
      font-size: 36px;
      line-height: 36px;
      margin: 0 0 60px;
    }
  }

  @media all and (max-width: 1024px) {
    padding: 140px 0 0;
  }

  .blog_items {
    display: flex;
    margin: 0 -20px 30px;
    flex-wrap: wrap;
    position: relative;

    &:after {
      visibility: hidden;
      position: absolute;
      content: 'Loading...';
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $Plex-400;
      color: #fff;
      font-size: 20px;
      background: rgba(0, 0, 0, 0.9);
    }

    &.postLoading {
      &:after {
        visibility: visible;
      }
    }

    @media all and (max-width: 1200px) {
      margin: 0 -10px 30px;
    }

    .no_posts {
      display: flex;
      justify-content: center;
      font-size: 24px;
      color: #FFFFFF;
      font-family: $Plex-400;
      width: 100%;
    }

    .post_item {

      padding: 20px;
      box-sizing: border-box;
      width: 33.3%;
      @media all and (max-width: 1200px) {
        padding: 20px 10px;
      }
      @media all and (max-width: 1024px) {
        width: 50%;
      }
      @media all and (max-width: 576px) {
        width: 100%;
      }

      &_wrapper {

      }

      &_img {
        display: block;
        text-decoration: none;
        height: 280px;
        margin-bottom: 24px;
        @media all and (max-width: 768px) {
          height: 180px;
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 10px;
          transform: scale(1);
          transition: all 0.3s ease-in-out;

          &:hover {
            transform: scale(1.05);
            transition: all 0.3s ease-in-out;
          }
        }
      }

      h2 {
        margin: 0;
      }

      &_title {
        display: block;
        text-decoration: none;
        font-family: $Plex-700;
        font-size: 18px;
        color: $white_text_color;
        margin: 0 0 14px;

        &:hover {
          text-decoration: underline;
        }
      }

      &_text {
        font-family: $Plex-400;
        font-size: 16px;
        color: $white_text_color;
        margin: 0 0 12px;
        line-height: 28px;
      }

      &_date {
        font-family: $Plex-400;
        font-size: 12px;
        color: $white_text_color_50;
      }
    }
  }

  .pagination_wrapper {
    margin: 0 auto 40px;
    max-width: 320px;

    .pagination_items {
      display: flex;
      margin: 0 -8px;
      justify-content: center;
      overflow: auto;
      @media all and (max-width: 768px) {
        margin: 0 -4px;
      }

      .pagination_item_wrapper {
        .pagination_item {

          &.active {
            background: #fff;
            color: #000;
          }

          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #fff;
          color: #fff;
          font-family: $Plex-400;
          @media all and (max-width: 1024px) {
            width: 30px;
            height: 30px;
          }
          @media all and (max-width: 768px) {
            width: 25px;
            height: 25px;
            font-size: 12px;
          }

        }

        padding: 0 8px;
        box-sizing: border-box;
        @media all and (max-width: 768px) {
          padding: 0 4px;
        }

      }
    }
  }
}