.join_app {
  padding: 0 0 80px;
  box-sizing: border-box;

  &_wrapper {
    display: flex;
    border-radius: 64px;
    overflow: hidden;
    align-items: stretch;
    @media all and (max-width: 1024px) {
      border-radius: 20px;
    }
    @media all and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &_media {
    width: 50%;
    @media all and (max-width: 767px) {
      width: 100%;
    }

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &_info {
    width: 50%;
    background: #EB001B;
    padding: 60px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media all and (max-width: 1024px) {
      padding: 30px;
    }
    @media all and (max-width: 767px) {
      width: 100%;
    }

    &_title {
      color: #FFF;
      font-family: $Plex-400;
      font-size: 56px;
      font-style: normal;
      font-weight: 400;
      line-height: 64px; /* 114.286% */
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      margin: 0 0 40px;
      @media all and (max-width: 1300px) {
        font-size: 44px;
        line-height: 44px;
      }
      @media all and (max-width: 1300px) {
        font-size: 36px;
      }
      @media all and (max-width: 1024px) {
        font-size: 28px;
        line-height: 28px;
      }

      span {
        &:nth-child(2n+2) {
          color: transparent;
          -webkit-text-stroke: 1px rgb(255, 255, 255);
        }

      }
    }
    &_cta {
      display: flex;
      align-items: center;

      .sex {
        display: block;
        margin: 0 0 0 auto;
      }
    }
  }
}