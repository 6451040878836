.page_disclaimer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: all 1s ease-in-out;
  padding: 16px;
  box-sizing: border-box;
  z-index: -1;

  &.show {
    opacity: 1;
    z-index: 100;
  }

  &_content {
    display: flex;
    justify-content: center;
    max-width: 400px;
    width: 100%;
    background: #fff;
    border-radius: 20px;
    border: 1px solid rgba(25, 25, 25, 0.12);
    box-shadow: 0px 10px 40px 0px rgba(51, 102, 255, 0.08);
    padding: 24px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    .disclaimer_logo {
      margin-bottom: 30px;
    }

    h2 {
      font-size: 22px;
      font-family: $Arial-700;
      margin: 0 0 16px;
      color: $blue_color;
    }

    p {
      text-align: center;
      font-size: 16px;
      font-family: $Arial-400;
      margin: 0 0 20px;
    }

    .main_button {
      width: 100%;
      padding: 8px 32px;

      &.blue {
        margin-bottom: 10px;
      }

      &.transparent {
        border-color: $black_text_color;
        color: $black_text_color;

        svg {
          path {
            stroke: $black_text_color;
          }
        }

        &:hover {
          background: $black_text_color;
          color: $white_text_color;

          svg {
            path {
              stroke: $white_text_color;
            }
          }
        }
      }
    }
  }
}
