
.featured {
  padding: 0;
  margin-top: -65px;

  &_wrapper {
    box-sizing: border-box;
    padding-bottom: 100px;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid $white_text_color;
    @media all and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
      padding-bottom: 40px;
      padding-top: 60px;
    }

    @media all and (max-width: 576px) {
      padding-bottom: 20px;
    }

  }

  &_title {
    font-family: $Plex-500;
    text-transform: uppercase;
    font-size: 36px;
    font-style: normal;
    line-height: 28px;
    color: $white_text_color;
    letter-spacing: 12px;
    margin: 0 64px 0 0;

    @media all and (max-width: 1200px) {
      font-size: 24px;
      letter-spacing: 6px;
    }

    @media all and (max-width: 1024px) {
      font-size: 36px;
      margin: 0 0 40px;
    }
  }

  &_items {
    display: flex;

    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;

    @media all and (max-width: 576px) {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }

  &_item {
    padding: 0 32px;
    box-sizing: border-box;
    @media all and (max-width: 1024px) {
      padding: 0 16px;
    }
    @media all and (max-width: 576px) {
      width: 50%;
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
    }

    &:first-child {
      padding-left: 0;
      @media all and (max-width: 767px) {
        padding-left: inherit;
      }
    }

    &:last-child {
      padding-right: 0;

      @media all and (max-width: 767px) {
        padding-right: inherit;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: contain;
      @media all and (max-width: 767px) {
        max-width: 140px;
      }
      @media all and (max-width: 576px) {
        max-width: 125px;
      }
    }
  }
}