.main_buy_button {
    padding: 12px 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    color: $black_text_color;
    font-family: $Plex-700;
    font-size: 20px;
    font-style: normal;
    line-height: 28px;
    border-radius: 50px;
    white-space: nowrap;
    border: 1px solid;
    background: $white_text_color;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
        background: $black_text_color;
        color: $white_text_color;
        border-color: $white_text_color;
        transition: all 0.3s ease-in-out;
    }

    @media all and (max-width: 1024px) {
        font-size: 16px;
        padding: 12px 24px;
    }

    img {
      margin-left: 24px;
      display: block;
        @media all and (max-width: 1024px) {
            width: 30px;
            height: auto;
        }
    }

    &.transparent {
        color: #fff;
        border: 1px solid #fff;
        background: transparent;
        transition: all 0.3s ease-in-out;

        &:hover {
            transition: all 0.3s ease-in-out;
            background: $white_text_color;
            color: $black_text_color;
        }
    }

}