.progress_section {
  padding: 60px 0 200px;
  overflow: hidden;

  @media all and (max-width: 1024px) {
    padding: 60px 0 100px;
  }

  &_wrapper {


    .progress_stage {
      position: relative;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      margin: 0 0 60px;

      @media all and (max-width: 1024px) {
        margin: 0 0 40px;
      }

      @media all and (max-width: 767px) {
        //padding: 10px 16px;
        flex-direction: column;
      }


      &_stage {
        display: flex;

        .stage_data {
          display: flex;
          max-width: 500px;
          flex-wrap: wrap;
          align-items: center;
          @media all and (max-width: 1100px) {
            max-width: 341px;
          }
          @media all and (max-width: 767px) {
            margin-bottom: 30px;
          }

          &_value {
            color: $white_text_color;
            font-family: $Plex-700;
            font-size: 20px;
            font-style: normal;
            line-height: 28px;
            padding: 5px 48px;
            box-sizing: border-box;
            border-radius: 500px;
            margin-right: 24px;
            border: 1px solid $white_text_color;
            @media all and (max-width: 1100px) {
              font-size: 12px;
              line-height: 20px;
              padding: 5px 16px;
            }
          }

          &_text {
            text-transform: uppercase;
            color: $white_text_color;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: $Plex-300;
            font-size: 64px;
            font-style: normal;
            line-height: 72px;
            display: block;

            @media all and (max-width: 1100px) {
              font-size: 42px;
              line-height: 42px;
            }

            @media all and (max-width: 767px) {
              font-size: 36px;
              line-height: 36px;
            }
          }

        }
      }

      &_raised {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;

        .raised_data {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;

          &_title {
            color: $white_text_color;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: $Plex-400;
            font-size: 21px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            display: block;
            margin-bottom: 10px;
            text-transform: uppercase;
            @media all and (max-width: 1024px) {
              font-size: 16px;
            }

            @media all and (max-width: 767px) {
              margin-bottom: 6px;
            }
          }

          &_value {
            color: $white_text_color;
            font-family: $Cute;
            font-size: 96px;
            font-style: normal;
            font-weight: 400;
            line-height: 66px;
            @media all and (max-width: 1024px) {
              font-size: 56px;
              line-height: 1;
            }
          }
        }
      }
    }

    .progressbar_titles {
      display: flex;
      margin-bottom: 16px;
      justify-content: space-between;

      &_sold, &_remaining {
        color: $white_text_color;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: $Arial-400;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
      }
    }

    .progress_progress {
      overflow: hidden;
      position: relative;
      border-radius: 40px;
      padding: 0 30px;
      height: 80px;
      box-sizing: border-box;
      border: 2px solid rgba(255, 255, 255, 0.24);
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;
      @media all and (max-width: 1024px) {
        margin-bottom: 40px;
      }

      @media all and (max-width: 767px) {
        padding: 10px 16px;
        height: 60px;
      }

      &:after {
        position: absolute;
        content: attr(data-pct);
        height: 100%;
        width: var(--prog);
        top: 0;
        left: 0;
        opacity: 0.8;
        border-radius: 50px;
        background: linear-gradient(90deg, #F6FC5A 0%, #FF596C 94.46%);
        z-index: -1;
      }

      .pct_sold {
        display: flex;
        align-items: center;

        &_value {
          font-feature-settings: 'clig' off, 'liga' off;
          text-shadow: 2px 2px 0 rgba(25, 25, 25, 0.12);
          font-style: normal;
          margin-right: 14px;
          color: $black_text_color;
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: $Cute;
          font-size: 96px;
          font-weight: 400;
          line-height: 20px; /* 20.833% */
          @media all and (max-width: 1024px) {
            font-size: 36px;
          }
          @media all and (max-width: 767px) {
            font-size: 24px;
          }

          &.wh {
            color: $white_text_color;
            @media all and (max-width: 1024px) {
              font-size: 36px;
            }
            @media all and (max-width: 767px) {
              font-size: 24px;
            }

          }
        }
      }

      .remaining {
        display: flex;
        align-items: center;

        &_value {
          color: $white_text_color;
          text-align: right;
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: $Cute;
          font-size: 64px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 31.25% */
          @media all and (max-width: 1024px) {
            font-size: 36px;
          }

          @media all and (max-width: 767px) {
            font-size: 24px;
          }

          &_title {
            font-size: 34px;
            font-family: $Plex-700;
            padding-left: 10px;
            @media all and (max-width: 1024px) {
              font-size: 20px;
            }

            @media all and (max-width: 767px) {
              font-size: 12px;
              padding-left: 4px;
            }
          }
        }
      }
    }

    .progress_data {
      display: flex;
      margin: 0 -10px 36px;
      justify-content: space-between;

      &_price {
        align-items: flex-start;
        flex: 1;
      }

      &_sold {
        align-items: center;
        flex: 1;

        .value {
          font-size: 64px !important;
          @media all and (max-width: 1024px) {
            font-size: 36px !important;
          }
          @media all and (max-width: 767px) {
            font-size: 24px !important;
          }
        }
      }

      &_next {
        align-items: flex-end;
        flex: 1;
      }


      &_item {
        padding: 0 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .title {
          color: $white_text_color_50;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Arial-400;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          display: block;
          margin-bottom: 10px;

          @media all and (max-width: 1024px) {
            font-size: 12px;
            margin-bottom: 0;
          }
          @media all and (max-width: 767px) {
            white-space: nowrap;
          }
          @media all and (max-width: 576px) {
            font-size: 10px;
          }
        }

        .value {
          color: $white_text_color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Cute;
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          @media all and (max-width: 1024px) {
            font-size: 24px;
          }
        }
      }
    }

    .progress_ccy {
      display: flex;
      justify-content: center;
      margin-bottom: 104px;

      @media all and (max-width: 1024px) {
        margin-bottom: 82px;
      }

      @media all and (max-width: 767px) {
        margin-bottom: 62px;
      }
    }

    .progress_cta {
      text-decoration: none;
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 0 0 34px;

      .main_button {
        width: 100%;
        padding: 16px 48px;
      }
    }
  }

  .lines {
    display: flex;
    justify-content: center;
    position: relative;

    .blur {
      position: absolute;
      left: 30%;
      width: 308px;
      height: 293px;
      flex-shrink: 0;
      border-radius: 308px;
      background: #EB001B;
      filter: blur(200px);
      @media all and (max-width: 1024px) {
        width: 208px;
        height: 193px;
        filter: blur(100px);
      }
    }

    .line {
      top: 50%;
      content: '';
      position: absolute;
      height: 66px;
      width: 102vw;
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      @media all and (max-width: 1024px) {
        height: 56px;
      }

      &_item {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-family: $Plex-400;

        @media all and (max-width: 1024px) {
          font-size: 16px;
        }

        @media all and (max-width: 767px) {
          font-size: 12px;
        }

        .bold {
          font-family: $Plex-700;
        }

        svg {
          margin: 0 40px 0 24px;

          @media all and (max-width: 767px) {
            margin: 0 10px 0 6px;
            width: 12px;
          }
        }
      }

      &.one {
        margin-left: -300px;
        width: calc(102vw + 300px);
        z-index: 2;
        transform: rotate(1.954deg) translateY(-50%);
        background: #FFFFA6;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.50);
      }

      &.two {
        z-index: 1;

        transform: rotate(-3.087deg) translateY(-50%);
        background: #a3a06f;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
      }
    }

    .image {
      position: relative;
      max-width: 330px;
      z-index: 3;

      @media all and (max-width: 1024px) {
        max-width: 173px;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        transform: rotate(-4.126deg);
      }
    }
  }
}



