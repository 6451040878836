//Text Colors
$white_text_color: rgb(255, 255, 255);
$white_text_color_80: rgba(250, 250, 250, 0.80);
$white_text_color_70: rgba(250, 250, 250, 0.70);
$white_text_color_50: rgba(250, 250, 250, 0.5);
$black_text_color: #191919;




$green_text_color: #61FF88;
$black_text_color_50: rgba(25, 25, 25, 0.5);
$black_text_color_70: rgba(25, 25, 25, 0.7);
$black_text_color_08: rgba(25, 25, 25, 0.08);
$black_text_color_024: rgba(25, 25, 25, 0.24);

//Fonts
$Plex-300: Plex-300, san-serif;
$Plex-400: Plex-400, san-serif;
$Plex-500: Plex-500, san-serif;
$Plex-600: Plex-600, san-serif;
$Plex-700: Plex-700, san-serif;
$Cute: Cute, san-serif;


$DS: DS, san-serif;
$Kameron-400: Kameron-400, san-serif;
$Kameron-500: Kameron-500, san-serif;
$Kameron-600: Kameron-600, san-serif;
$Kameron-700: Kameron-700, san-serif;
$Arial-400: Arial-400, san-serif;
$Arial-700: Arial-700, san-serif;

//Colors
$bg_color: #18151E;



$blue_color: #3366FF;
$blue_color_012: rgba(51, 102, 255, 0.12);
$blue_color_24: rgba(51, 102, 255, 0.24);
$blue_color_08: rgba(51, 102, 255, 0.08);
$blue_color_06: rgba(51, 102, 255, 0.06);
$blue_color_04: rgba(51, 102, 255, 0.04);
$black_color: #000;
$error_color: #EE0004;
$red_color: #EB001B;