.team {
  &_wrapper {
    padding: 160px 0 0;
    border-top: 1px solid #fff;
    @media all and (max-width: 1024px){
      padding: 80px 0 0;
    }

    .team_items {
      display: flex;
      margin: 0 -20px 0;
      @media all and (max-width: 1024px){
        margin: 0 -10px 0;
      }

      @media all and (max-width: 767px){
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
      }


      .team_item {
        padding: 0 20px 0;
        box-sizing: border-box;
        flex: 1;
        position: relative;
        display: flex;
        @media all and (max-width: 1024px){
          padding: 0 10px 0;
        }

        @media all and (max-width: 767px){
          flex: initial;
          width: 50%;
          justify-content: center;
        }

        img {
          width: 100%;
          height: auto;
        }

        &:nth-child(2) {
          @media all and (max-width: 767px){
            margin-bottom: 20px;
          }
        }

        &.item_title {
          width: 290px;
          @media all and (max-width: 1024px){
            width: 160px;
            flex: initial;
          }

          @media all and (max-width: 767px){
            width: 50%;
          }

          h2 {
            color: $white_text_color;
            font-family: $Plex-400;
            font-size: 96px;
            font-style: normal;
            font-weight: 400;
            line-height: 96px; /* 100% */
            text-transform: uppercase;
            margin: 0;
            @media all and (max-width: 1200px){
              font-size: 80px;
            }
            @media all and (max-width: 1024px){
              font-size: 36px;
              line-height: 36px;
            }

            span {
              &:nth-child(2) {
                -webkit-text-stroke: 1px $white_text_color;
                color: $black_text_color;
              }
            }
          }
        }

        &_info {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 73%;

          @media all and (max-width: 1200px){
            top: 60%;
          }

          .name {
            color: $white_text_color;
            text-align: center;
            font-family: $Plex-600;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px; /* 116.667% */
            margin: 0;
            @media all and (max-width: 1200px){
              font-size: 20px;
            }
          }

          .position {
            color: $white_text_color;
            font-family: $Plex-300;
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            text-align: center;
            line-height: 28px;
            margin: 0;
            @media all and (max-width: 1200px){
              font-size: 16px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
}