@import "variables";

.policy_section {
  color: #fff;
  padding: 260px 0 0;

  @media all and (max-width: 1024px){
    padding: 140px 0 0;
  }

  h1 {
    font-family: $Plex-700;
    font-size: 40px;
    margin: 0 0 60px;
    text-align: center;
    @media all and (max-width: 767px){
      font-size: 32px;
    }
  }

  h4 {
    font-family: $Plex-700;
    font-size: 24px;
    margin: 0 0 30px;
    @media all and (max-width: 767px){
      font-size: 20px;
    }
  }

  h6 {
    font-family: $Plex-700;
    font-size: 18px;
    margin: 0 0 30px;
    @media all and (max-width: 767px){
      font-size: 16px;
    }
  }

  p {
    font-family: $Plex-400;
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 12px;
    @media all and (max-width: 767px){
      font-size: 14px;
      line-height: 18px;
    }
  }
  .policy_section_item {
    margin-bottom: 40px;
  }
}