@font-face {
    font-family: 'Cute';
    src: url("/fonts/Cute_Font/CuteFont-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    font-stretch: normal;
}

@font-face {
    font-family: 'Plex-300';
    src: url("/fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    font-stretch: normal;
}

@font-face {
    font-family: 'Plex-400';
    src: url("/fonts/IBM_Plex_Mono/IBMPlexMono-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    font-stretch: normal;
}

@font-face {
    font-family: 'Plex-500';
    src: url("/fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plex-600';
    src: url("/fonts/IBM_Plex_Mono/IBMPlexMono-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Plex-700';
    src: url("/fonts/IBM_Plex_Mono/IBMPlexMono-SemiBold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}




@font-face {
    font-family: 'DS';
    src: url("/fonts/DS/ds.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    font-stretch: normal;
}

@font-face {
    font-family: 'Kameron-400';
    src: url("/fonts/Kameron/static/Kameron-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    font-stretch: normal;
}

@font-face {
    font-family: 'Kameron-500';
    src: url("/fonts/Kameron/static/Kameron-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kameron-600';
    src: url("/fonts/Kameron/static/Kameron-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}




@font-face {
    font-family: 'Kameron-700';
    src: url("/fonts/Kameron/static/Kameron-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial-400';
    src: url("/fonts/ArialNew/ARIALLGT.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial-700';
    src: url("/fonts/Arial/Arial-Bold.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}