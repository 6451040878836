
.modal {
  box-sizing: border-box;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgba($color: #000000, $alpha: 0.4);
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;


  &.active {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease-in-out;
  }

  &__content {
    width: 100%;
    max-width: 600px;
    margin: auto 16px;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: linear-gradient(180deg, #0d120f 0%, #111515 100%);
    position: relative;
    transition: inherit;
    opacity: 0;
    color: #fff;
    font-family: $Kameron-700;
    visibility: hidden;
    transform: translateY(-100px);

    .modal_subtitle {
      margin-bottom: 16px;
      display: block;
      font-family: $Kameron-500;
      font-size: 20px;
      color: #607290;
      @media all and (max-width: 700px) {
        padding-top: 14px;
        font-size: 16px;
      }
    }
  }

  &.active &__content {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  &__header {
    padding: 20px 36px;
    @media all and (max-width: 700px) {
      padding: 20px 16px 0px;
    }

    h2 {
      @media all and (max-width: 700px) {
        font-size: 18px !important;
      }
    }
  }

  &__body,
  &__footer {
  }

  &__body {
    padding: 0;
    video {
      border-radius: 20px;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__close {
    position: absolute;
    top: 16px;
    z-index: 2;
    right: 20px;
    font-size: 1.5rem;
    cursor: pointer;
    color: #7f7f7f;
  }
}