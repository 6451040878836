.universe {
  overflow: hidden;
  margin-top: -150px;
  height: 1330px;
  background-image: url("/img/home/universe/universe_bg.png");
  background-repeat: no-repeat;
  background-position: center bottom;

  @media all and (max-width: 1200px) {
    height: 1270px;
  }

  @media all and (max-width: 1024px) {
    height: 900px;
    margin-top: -120px;
    padding-top: 70px;
    background-size: cover;
  }

  @media all and (max-width: 767px) {
    height: auto;
    margin-top: -80px;
    background-image: url("/img/home/universe/universe_bg_small.png");
    background-position: right -580px bottom;
  }


  .container {
    height: 100%;
  }

  &_wrapper {
    position: relative;
    height: 100%;
    @media all and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &_title {
    color: $white_text_color;
    text-align: center;
    font-family: $Plex-400;
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    line-height: 96px;
    text-transform: uppercase;
    margin: 0 auto 40px;
    max-width: 620px;

    @media all and (max-width: 1024px) {
      font-size: 48px;
      line-height: 48px;
      max-width: 460px;
    }
  }

  .u_image {
    position: absolute;
    @media all and (max-width: 767px) {
      position: static;
      width: 100%;
      height: auto;
    }
  }

  .u_dating {
    left: -100px;
    @media all and (max-width: 1200px) {
      width: 44%;
      height: auto;
      top: 30%;
    }
    @media all and (max-width: 767px) {
      width: 100%;
      max-width: 300px;
    }
  }

  .u_p_t_d {
    left: 37%;
    @media all and (max-width: 1200px) {
      width: 44%;
      height: auto;
    }
    @media all and (max-width: 767px) {
      width: 100%;
      max-width: 300px;
    }
  }

  .u_nfts {
    right: -100px;
    top: 600px;
    @media all and (max-width: 1200px) {
      width: 44%;
      height: auto;
    }
    @media all and (max-width: 1024px) {
      top: 40%;
    }
    @media all and (max-width: 767px) {
      width: 100%;
      max-width: 300px;
    }
  }

  .u_competitions {
    left: 30%;
    bottom: 0;
    @media all and (max-width: 1200px) {
      width: 60%;
      height: auto;
      left: 25%;
    }
    @media all and (max-width: 767px) {
      width: 100%;
      max-width: 300px;
    }
  }
}