.how_to_buy_section {
  box-sizing: border-box;
  padding: 260px 0 0;

  @media all and (max-width: 1024px) {
    padding: 140px 0 0;
  }

  &.post {
    padding: 90px 0 130px;
    @media all and (max-width: 1024px) {
      padding: 50px 0 90px;
    }
  }

  &_wrapper {
    display: flex;

    @media all and (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }

    .list_category {
      margin-right: 120px;

    }

    .how_to {
      display: flex;
      flex-direction: column;

      &_title {
        color: $white_text_color;
        font-family: $Plex-400;
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: 64px;
        margin: 0 0 80px;
        @media all and (max-width: 1200px) {
          font-size: 40px;
          line-height: 52px;
        }
        @media all and (max-width: 1024px) {
          font-size: 36px;
          line-height: 36px;
          margin: 0 0 60px;
        }
      }

      &_subtitle {
        border-radius: 16px;
        background: $white_text_color_50;
        padding: 5px 20px;
        display: flex;
        color: $white_text_color;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: $Plex-400;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        align-self: flex-start;
        margin: 0 0 60px;
      }

      &_items {
        .how_to_item {
          display: flex;
          margin-bottom: 54px;
          @media all and (max-width: 1024px) {
            flex-wrap: wrap;
          }

          &_img {
            display: block;
            width: 100%;
            margin: 0 36px 0 0;
            max-width: 400px;
            position: relative;
            border-radius: 10px;
            overflow: hidden;
            @media all and (max-width: 1200px) {
              max-width: 280px;
            }
            @media all and (max-width: 1024px) {
              max-width: 400px;
              margin: 0 0 30px;
            }
            @media all and (max-width: 767px) {
              max-width: 280px;
              margin: 0 36px 0 0;
            }
            @media all and (max-width: 576px) {
              max-width: 360px;
              margin: 0 0 30px;
            }

            .resize {
              position: absolute;
              right: -6px;
              bottom: 0;
              cursor: pointer;
              width: 70px;
              height: auto;
            }

            .item_img {
              display: block;
              height: auto;
              width: 100%;
            }
          }

          &_desc {
            padding-left: 60px;
            box-sizing: border-box;
            position: relative;
            flex: 1;

            @media all and (max-width: 576px) {
              width: 100%;
              flex: initial;
            }

            &:after {
              content: attr(data-number);
              width: 42px;
              height: 42px;
              border-radius: 50%;
              background: $red_color;
              display: flex;
              align-items: center;
              justify-content: center;
              color: $white_text_color;
              text-align: center;
              font-family: $Plex-700;
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: 48px;
              position: absolute;
              top: 0;
              left: 0;
            }

            &:before {
              content: '';
              height: 54px;
              width: 1px;
              background: rgba(25, 25, 25, 0.24);
              position: absolute;
              top: 30px;
              left: 21px;
            }

            h3 {
              color: $white_text_color;
              font-feature-settings: 'clig' off, 'liga' off;
              font-family: $Plex-700;
              font-size: 21px;
              font-style: normal;
              font-weight: 700;
              line-height: 36px; /* 171.429% */
              margin: 0;
            }

            p {
              color: $white_text_color;
              font-family: $Plex-400;
              font-size: 16px;
              font-style: normal;
              letter-spacing: 0.6px;
              font-weight: 400;
              line-height: 24px; /* 150% */
            }
          }
        }
      }
    }


    .list_category {
      width: 290px;
      max-width: 290px;
      top: 100px;
      margin-right: 150px;
      display: flex;
      flex-direction: column;

      &.post {
        @media all and (max-width: 1200px) {
          width: auto;
          max-width: 240px;
        }
        @media all and (max-width: 767px) {
          order: 2;
          margin-right: 0;
          max-width: none;
          .category_item {
            width: initial;
          }
        }
      }

      @media all and (max-width: 1200px) {
        width: auto;
        max-width: 220px;
      }

      @media all and (max-width: 1200px) {
        max-width: 200px;
      }
      @media all and (max-width: 767px) {
        position: static;
      }

      .post_head {
        display: flex;
        align-items: center;
        padding-bottom: 26px;
        border-bottom: 4px solid #fff;
        margin-bottom: 26px;
        @media all and (max-width: 1024px) {
          margin-bottom: 20px;
          padding-bottom: 20px;
        }

        &_img {
          display: block;
          margin-right: 22px;
          @media all and (max-width: 1024px) {
            width: 48px;
          }
        }

        &_info {
          display: flex;
          flex-direction: column;

          h4, .underline {
            color: $white_text_color;
            font-family: $Plex-700;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
            margin: 0;
            @media all and (max-width: 1024px) {
              font-size: 16px;
            }
          }

          .underline {
            text-decoration: underline;
          }

          span {
            color: $white_text_color_50;
            font-family: $Cute;
            letter-spacing: 0.6px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            @media all and (max-width: 1024px) {
              font-size: 12px;
            }
          }
        }
      }

      .post_share {
        display: flex;
        align-items: center;
        margin-bottom: 44px;

        h5 {
          color: $white_text_color;
          font-family: $Plex-400;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 48px;
          margin: 0 30px 0 0;
        }

        &_icons {
          display: flex;

          a {
            display: block;
            margin-right: 30px;

            &:last-child {
              margin-right: 0;
            }
          }

          svg {
            display: block;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

.full_view {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: all 1s ease-in-out;
  padding: 16px;
  box-sizing: border-box;
  z-index: -1;

  &.show {
    opacity: 1;
    z-index: 100;
  }

  &_content {
    display: flex;
    justify-content: center;
    max-width: 742px;
    height: auto;
    width: 100%;
    border-radius: 20px;
    border: 1px solid rgba(25, 25, 25, 0.12);
    box-shadow: 0px 10px 40px 0px rgba(51, 102, 255, 0.08);
    padding: 0;
    margin: 16px;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    position: relative;

    &_close {
      position: absolute;
      cursor: pointer;
      right: 20px;
      top: 20px;
      @media all and (max-width: 767px) {
        right: 6px;
        top: 6px;
        width: 14px;
        height: auto;
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

.how_to {
  display: flex;
  flex-direction: column;
}