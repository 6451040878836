@import "aos";
@import "variables";
@import "header";
@import "./home/index";
@import "commons/index";
@import "ico-moon-fonts.css";
@import "./accordion_styles/index";
@import "footer";
@import "privacy";
@import "loader";
@import "disclaimer";
@import "how_to_buy";
@import "blog";

html {
  scroll-behavior: smooth;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

body {
  margin: 0;
  padding: 0;
  background: $bg_color;

  &.is_menu_visible {
    //overflow: hidden;
  }
}

.container {
  max-width: 1400px;
  width: 100%;
  padding: 0 60px;
  margin: 0 auto;
  box-sizing: border-box;
  @media all and (max-width: 1024px) {
    padding: 0 24px;
    max-width: 808px;
  }
  @media all and (max-width: 767px) {
    padding: 0 16px;
  }
}

.not_found {
  padding: 200px 0;
  box-sizing: border-box;
  @media all and (max-width: 767px){
    padding: 150px 0;
  }

  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &_title {
      font-family: $Plex-700;
      font-size: 60px;
      color: #EE0004;
      @media all and (max-width: 767px){
        font-size: 40px;
      }
    }
    &_subtitle {
      font-family: $Plex-400;
      font-size: 22px;
      color: #fff;
      text-align: center;
      margin: 0 0 30px;
      @media all and (max-width: 767px){
        font-size: 16px;
      }
    }

    .home_button {
      text-decoration: none;
    }
  }
}