.top_line {
  position: fixed;
  left: 0;
  right: 0;
  padding: 10px 0;
  top: 0;
  z-index: 3;
  background: $blue_color;
  transition: all 0.3s ease-in-out;

  &_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      font-size: 16px;
      letter-spacing: 0.8px;
      display: flex;
      align-items: center;
      color: #fff;
      font-family: $Arial-400;
      margin: 0 10px 0 0;
      line-height: 28px;
      .bitmart {
        width: 20px;
        border-radius: 8px;
        padding: 4px;
        box-sizing: border-box;
        background: #fff;
        margin-right: 6px;
        img {
          display: block;
        }
      }

      span {
        white-space: nowrap;
      }
    }
  }

  .countdown_container {
    .countdown_wrapper {
      display: flex;
      justify-content: space-between;
      @media all and (max-width: 992px) {
        max-width: 400px;
        margin: 0 auto;
      }

      .count_item {
        //min-width: 70px;
        font-family: $DS;
        color: #fff;
        display: flex;
        align-items: baseline;
        line-height: 1.1;
        margin-right: 6px;
        //justify-content: center;
        @media all and (max-width: 576px) {
          //min-width: 60px;
        }

        .count {
          font-size: 26px;
          margin-right: 2px;
          @media all and (max-width: 576px) {
            //font-size: 44px;
            //min-width: 42px;
          }
        }

        .count_name {
          font-size: 14px;
        }
      }
    }
  }

}