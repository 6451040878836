.roadmap {
  padding: 110px 0 80px;
  box-sizing: border-box;
  overflow: hidden;
  @media all and (max-width: 1024px) {
    padding: 10px 0 80px;
    overflow: hidden;
  }

  &_title {
    color: $white_text_color;
    text-align: center;
    font-family: $Plex-400;
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    line-height: 96px; /* 100% */
    text-transform: uppercase;
    margin: 0 0 78px;
    @media all and (max-width: 1024px) {
      font-size: 48px;
      margin: 0 0 40px;
      line-height: 48px;
    }
  }

  &_wrapper {
    display: flex;
    margin: 0 -20px 90px;
    position: relative;
    @media all and (max-width: 1024px) {
      margin: 0 -10px 40px;
    }
    @media all and (max-width: 767px) {
      flex-wrap: wrap;
    }

    &:after {
      content: '';
      position: absolute;
      border-radius: 303px;
      background: #EB001B;
      filter: blur(200px);
      width: 303px;
      height: 289px;
      right: -100px;
      bottom: 10%;
      z-index: -1;
    }

    &:before {
      content: '';
      position: absolute;
      border-radius: 303px;
      background: #EB001B;
      filter: blur(200px);
      width: 303px;
      height: 289px;
      left: -100px;
      top: 0;
      z-index: -1;
      @media all and (max-width: 1024px) {
        display: none;
      }
    }

    .roadmap_item {
      padding: 0 20px 0;
      box-sizing: border-box;
      width: 25%;
      @media all and (max-width: 1024px) {
        padding: 0 10px 0;
      }
      @media all and (max-width: 767px) {
        width: 50%;
      }

      &:nth-child(1){
        @media all and (max-width: 767px) {
            margin-bottom: 40px;
          }
      }
      &:nth-child(2){
        @media all and (max-width: 767px) {
            margin-bottom: 40px;
          }
      }

      &_img {
        position: relative;

        .phase_1 {
          left: 30px;
          top: 20px;
          @media all and (max-width: 767px) {
            top: 60px;
          }
        }

        .phase_2 {
          left: 30px;
          top: -20px;
          @media all and (max-width: 767px) {
            top: 30px;
          }
        }

        .phase_3 {
          left: 30px;
          top: -50px;
          @media all and (max-width: 767px) {
            top: 20px;
          }
        }

        .phase_4 {
          left: 30px;
          top: -100px;
          @media all and (max-width: 767px) {
            top: -20px;
          }
        }

        .row_img {
          position: absolute;
          width: 92%;
          height: auto;
          @media all and (max-width: 767px) {
            width: 120px;
          }
        }

        .main_img {
          width: 100%;
          display: block;
          height: auto;
          margin-bottom: 36px;
          max-width: 180px;
          @media all and (max-width: 1024px) {
            max-width: 124px;
          }
        }
      }

      &_title {
        color: $white_text_color;
        font-family: $Plex-600;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 116.667% */
        margin: 0 0 6px;
        @media all and (max-width: 1024px) {
          font-size: 18px;
        }
        @media all and (max-width: 767px) {
          line-height: 20px;
        }
      }

      &_list {
        padding: 0 0 0 14px;
        list-style: disc;

        li {
          color: $white_text_color;
          font-family: $Plex-400;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          margin: 0;
          @media all and (max-width: 1024px) {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }
  }

  &_cta {
    display: flex;
    justify-content: center;
    text-decoration: none;
  }
}