body.webp {
  .banner_section {
  }
}

.banner_bg {
  max-width: 1920px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

.banner_section {
  padding: 164px 0 0;
  background-image: url("/img/home/banner/banner_bg.png");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  z-index: 2;
  position: relative;

  @media all and (max-width: 1024px) {
    padding: 67px 0 0;
    background-size: 1370px;
    background-position: -178px -120px;
  }

  @media all and (max-width: 767px) {
    background-size: 300%;
    background-position: right 33% bottom -95%;
  }

  @media all and (max-width: 576px) {
    background-size: 300%;
    background-position: right 33% bottom -34%;
  }

  &_wrapper {
    padding: 60px 0;
    box-sizing: border-box;
    border-top: 2px solid #D9D9D9;
    border-bottom: 2px solid #D9D9D9;
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 1024px) {
      padding: 40px 0;
    }
    @media all and (max-width: 767px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    .main_banner {
      width: 100%;
      max-width: 604px;
      display: flex;
      flex-direction: column;
      padding-right: 40px;
      margin-right: 40px;
      box-sizing: border-box;
      align-items: flex-start;

      @media all and (max-width: 767px) {
        margin-bottom: 100px;
        margin-right: 0;
        padding-right: 0;
        max-width: none;
      }
      @media all and (max-width: 576px) {
        margin-bottom: 60px;
      }

      &_promo {
        position: relative;
        max-width: 530px;
        margin: 0 0 98px 0;
        color: $white_text_color;
        font-family: $Plex-300;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
        padding-left: 72px;
        box-sizing: border-box;
        @media all and (max-width: 1024px) {
          font-size: 16px;
          line-height: 20px;
          margin: 0 0 40px 0;
          padding-left: 36px;
        }
        @media all and (max-width: 767px) {

          max-width: 260px;
          margin: 0 auto 40px;
        }

        &:after {
          left: 0;
          top: 0;
          content: '';
          position: absolute;
          width: 83px;
          height: 79px;
          background: rgba(235, 0, 27, 0.40);
          filter: blur(25px);
          z-index: -1;
        }

        &:before {
          content: '';
          width: 36px;
          height: 36px;
          position: absolute;
          left: 0;
          top: -4px;
          background-size: cover;
          background-position: center;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCAzNiAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM2IDE4SDE4TTE4IDM2VjE4TTAgMThIMThNMTggMFYxOE0xOCAxOEwzMC43Mjc5IDUuMjcyMDhNMTggMThMNS4yNzIwOCAzMC43Mjc5TTE4IDE4TDUuMjcyMDggNS4yNzIwOE0xOCAxOEwzMC43Mjc5IDMwLjcyNzkiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");

          @media all and (max-width: 1024px) {
            width: 20px;
            height: 20px;
            top: 0;
          }
        }
      }

      &_title {
        color: $white_text_color;
        font-family: $Plex-400;
        font-size: 72px;
        font-style: normal;
        font-weight: 400;
        line-height: 72px;
        text-transform: uppercase;
        margin: 0 0 46px;

        @media all and (max-width: 1024px) {
          font-size: 42px;
          line-height: 42px;
          margin: 0 0 24px;
        }

        @media all and (max-width: 767px) {
          text-align: center;
          max-width: 340px;
          margin: 0 auto 24px;
        }
        @media all and (max-width: 576px) {
          font-size: 36px;
        }
      }

      &_subtitle {
        color: $white_text_color;
        font-family: $Plex-300;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        text-transform: uppercase;
        margin: 0 0 50px;
        max-width: 504px;
        @media all and (max-width: 1024px) {
          font-size: 16px;
          line-height: 20px;
          margin: 0 0 24px;
        }
        @media all and (max-width: 767px) {
          text-align: center;
          margin: 0 auto 36px;
          max-width: 294px;
        }
      }

      &_cta {
        display: flex;
        align-items: center;
        @media all and (max-width: 767px) {
          flex-direction: column;
          max-width: 294px;
          width: 100%;
          margin: 0 auto;

        }

        .buy_now {
          text-decoration: none;
          margin-right: 40px;
          @media all and (max-width: 1024px) {
            margin-right: 20px;
          }
          @media all and (max-width: 767px) {
            margin: 0 0 36px;
            width: 100%;

            button {
              width: 100%;
              justify-content: center;
            }
          }
        }

        .wp {
          color: $white_text_color;
          font-family: $Plex-700;
          font-size: 20px;
          font-style: normal;
          line-height: 28px; /* 140% */
          text-decoration-line: underline;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: $red_color;
            transition: all 0.3s ease-in-out;
          }

          @media all and (max-width: 1024px) {
            font-size: 16px;
          }
        }
      }
    }

    .media_banner {
      position: relative;
      width: 100%;
      height: auto;

      @media all and (max-width: 767px) {
        width: 100%;
        max-width: none;
        padding: 0 80px;
        margin: 0;
        box-sizing: border-box;
      }

      @media all and (max-width: 767px) {
        padding: 0 40px;
      }


      &_wrapper {
        border-radius: 24px;
        position: relative;
        height: 100%;
      }

      &_main {
        display: block;
        width: 100%;
        height: auto;

        @media all and (max-width: 1024px) {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        @media all and (max-width: 767px) {
          position: static;
          top: inherit;
          transform: inherit;
          max-width: 400px;
          margin: 0 auto;
        }

      }
    }
  }
}

