
.page_loader {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  background: $bg_color;
  z-index: 100;
  transition: all 1s ease-in-out;

  &_content {
    display: flex;
    justify-content: center;
  }

  &.loading {
    opacity: 1;
    z-index: 100;
    transition: all 1s ease-in-out;
  }

  &.loaded {
    opacity: 0;
    z-index: -1;
    transition: all 1s ease-in-out;
  }

  img {
    width: 88px;
    display: block;
  }
}
