.why_section {
  padding: 76px 0 0;
  box-sizing: border-box;

  &_wrapper {
    display: flex;
    @media all and (max-width: 767px) {
      flex-direction: column;
    }
  }

  .why_media {
    width: 50%;
    margin-right: 70px;
    @media all and (max-width: 1200px) {
      margin-right: 30px;
    }

    @media all and (max-width: 1024px) {
      margin-right: 15px;
    }

    @media all and (max-width: 767px) {
      width: 100%;
      margin: 0 0 40px;
    }

    &_title {
      width: 600px;
      color: #FFF;
      font-family: $Plex-400;
      font-size: 96px;
      font-style: normal;
      font-weight: 400;
      line-height: 96px;
      text-transform: uppercase;
      margin: 0 0 72px;

      @media all and (max-width: 1024px) {
        font-size: 54px;
        line-height: 54px;
        max-width: 300px;
      }

      @media all and (max-width: 767px) {
        font-size: 48px;
        line-height: 48px;
        margin: 0 0 40px;
      }
    }

    &_img {
      display: block;
      width: 100%;
      height: auto;
      @media all and (max-width: 767px) {
        max-width: 400px;
        margin: 0 auto;
      }
    }
  }

  .why_description {
    width: 50%;
    margin-left: 70px;
    padding-top: 108px;
    box-sizing: border-box;
    @media all and (max-width: 1200px) {
      margin-left: 30px;
    }
    @media all and (max-width: 1024px) {
      margin-left: 15px;
    }

    @media all and (max-width: 767px) {
      width: 100%;
      margin: 0;
      padding-top: 40px;
    }

    &_title {
      position: relative;
      color: #FFF;
      font-family: $Plex-500;
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      text-transform: uppercase;
      width: 395px;
      margin: 0 0 148px;

      @media all and (max-width: 1024px) {
        font-size: 24px;
        line-height: 20px;
        max-width: 300px;
        margin: 0 0 42px;
      }

      @media all and (max-width: 767px) {
        max-width: 320px;
      }

      .yellow {
        color: #F6FC5A;
      }

      .title_img {
        position: absolute;
        top: -90px;
        z-index: -1;
        left: 30px;
        width: 121px;
        height: 116px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 89, 108, 0.4);
        border-radius: 50%;
        @media all and (max-width: 767px) {
          width: 78px;
          height: 78px;
          top: -58px;
        }

        img {
          display: block;
          max-width: 95px;
          height: auto;
          @media all and (max-width: 767px) {
           max-width: 60px;
          }
        }
      }
    }

    &_list {
      .list_item {
        margin: 0 0 56px;
        @media all and (max-width: 1024px) {
          margin: 0 0 30px;
        }

        &:last-child {
          margin: 0;
        }

        &_title {
          display: flex;
          align-items: center;
          color: #FFF;
          font-family: $Plex-600;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
          margin: 0 0 6px;
          @media all and (max-width: 1024px) {
            font-size: 20px;
          }
        }

        &_description {
          color: #FFF;
          font-family: $Plex-400;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          margin: 0;
          @media all and (max-width: 1024px) {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
  }
}