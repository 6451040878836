@import "variables";

body.is_menu_visible {
  .nav_list_main {
    @media all and (max-width: 1024px) {
      transform: scaleY(1);
      transform-origin:  top;
      transition: 0.3s scale ease-in-out, 0.5s opacity ease-in-out;
      opacity: 1;
    }
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 48px 0;
  box-sizing: border-box;
  font-family: $Arial-400;
  z-index: 100;
  background: transparent;

  &.scrolled, &.blur {
    backdrop-filter: blur(10px);
  }
  @media all and (max-width: 576px) {
    backdrop-filter: blur(12px);
  }

  @media all and (max-width: 1024px) {
    padding: 16px 0;
    height: 67px;
  }

  &_wrapper {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    margin: 0 -16px;

    .nav_burger {
      display: none;
      //margin-left: auto;
      margin-right: 16px;
      margin-left: 16px;
      @media all and (max-width: 1024px) {
        display: block;
      }
      @media all and (max-width: 576px) {
        margin-left: 8px;
      }
    }

    .mobile_hidden {
      @media all and (max-width: 576px) {
        display: none !important;
      }
    }

    .main_button {
      @media all and (max-width: 576px) {
        padding: 6px 16px;
      }
    }
  }

  .nav_logo {
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 164px;
      @media all and (max-width: 1024px) {
        width: 84px;
      }

      @media all and (max-width: 576px) {
        max-width: 66px;
        width: 100%;
      }
    }
  }

  .nav_list_main, .nav_list_add {
    display: flex;
    @media all and (max-width: 1024px) {
      margin-left: auto;
    }

    &_items {
      display: flex;
      align-items: center;
    }

    .main_nav_item {
      padding: 0 16px 0;
      box-sizing: border-box;
      text-align: center;
      @media all and (max-width: 1200px) {
        padding: 0 8px 0;
      }

      a {
        color: $white_text_color;
        text-decoration: none;
        font-family: $Plex-400;
        line-height: 16px;
        font-size: 14px;
        text-align: center;

        &:hover {
          color: $red_color;
          transition: all 0.3s ease-in-out;
        }
      }
    }

    .nav_item {
      padding: 0 16px 0;
      box-sizing: border-box;
      display: flex;

      @media all and (max-width: 576px) {
        padding: 0 8px;
      }

      a {
        color: $white_text_color;
        text-decoration: none;
        font-family: $Plex-400;
        line-height: 16px;
        font-size: 12px;
      }
    }
  }

  .nav_list_main {
    @media all and (max-width: 1024px) {
      transform: scaleY(0);
      opacity: 0;
      transform-origin:  top;
      transition: 0.3s scale ease-in-out, 0.5s opacity ease-in-out;
      position: fixed;
      top: 67px;
      background: #18151E;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      display: flex;
      width: 100%;
      left: 0;
      right: 0;
      margin: 0;
      padding: 16px 8px;
      box-sizing: border-box;
      .nav_list_main_items {
        box-sizing: border-box;
        margin: 0;
        width: 100%;
        justify-content: center;
      }
    }
    @media all and (max-width: 767px) {
      .nav_list_main_items {
        flex-direction: column;

        .main_nav_item {
          margin-bottom: 12px;

          a {
            font-size: 14px;
          }
        }
      }
    }
    @media all and (max-width: 576px) {
      top: 67px
    }
  }

  .lang {
    .lang_list {
      position: relative;

      .lang_hidden_wrapper {
        display: flex;
        top: 100%;
        padding: 16px;
        position: absolute;
        transform: scaleY(0);
        transform-origin: top;
        box-sizing: border-box;
        right: 0;
        min-width: 200px;
        border-radius: 10px;
        background: $black_text_color;
        box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.08);
        backdrop-filter: blur(10px);
        z-index: 2;
        opacity: 0;
        transition: 0.3s scale ease-in-out, 0.5s opacity ease-in-out;

        ol {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }

        li {
          margin-bottom: 6px;
          width: 100%;

          a {
            width: 100%;
            white-space: nowrap;
            color: $black_text_color;
            font-size: 16px;
            text-decoration: none;
            font-family: $Plex-400;
            letter-spacing: 0.6px;
            font-weight: 400;
            display: flex;
            align-items: center;
            position: relative;

            .href_lang {
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;
              color: $white_text_color;
              font-size: 14px;
              line-height: 18px;
              font-family: $Plex-400;
              &:hover {
                color: $red_color;
                transition: all 0.3s ease-in-out;
              }
              &_img {
                display: block;
                width: 18px;
                height: 14px;
                overflow: hidden;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                }
              }
            }
          }
        }

      }


      &:hover {

        .lang_hidden_wrapper {
          transform: scaleY(1);
          transition: 0.3s scale ease-in-out, 0.5s opacity ease-in-out;
          opacity: 1;
        }

        .lang_current:after {
          transition: opacity 0.2s ease-in-out;
          opacity: 1;

        }
      }

      .lang_current {
        &:hover {
          .lang_current_img {
            .dropdown_svg {
              transform: rotateX(180deg);
            }
          }
        }

        &_img {
          display: flex;
          line-height: 16px;
          color: $white_text_color;
          text-decoration: none;
          font-family: $Plex-400;
          font-size: 12px;
          align-items: center;

          svg {
            margin-right: 4px;
            fill: #fff;
          }

          .dropdown_svg {
            margin-left: 5px;
          }
        }
      }
    }
  }

  .nav_buy_button {
    button {
      display: flex;
      align-items: center;
      padding: 4px 8px 4px 16px;
      box-sizing: border-box;
      border-radius: 500px;
      border: 1px solid #FFF;
      background: transparent;
      color: $white_text_color;
      font-family: $Plex-600;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: #fff;
        color: #191919;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
      }

      @media all and (max-width: 576px) {
        padding: 0 8px 0 14px;
      }

      .gegg_hidden {
        margin-left: 4px;
        @media all and (max-width: 576px) {
          display: none;
        }
      }

      @media all and (max-width: 576px) {
        font-size: 12px;
      }

      svg {
        display: block;
        margin-left: 12px;
        @media all and (max-width: 576px) {
          width: 16px;
        }
      }
    }

  }


}