.join {
  padding: 112px 0 250px;
  box-sizing: border-box;
  position: relative;

  @media all and (max-width: 1024px) {
    padding: 80px 0 120px;
  }
  @media all and (max-width: 767px) {
    padding: 80px 0 80px;
  }

  .birds_wrapper {
    position: absolute;
    top: 300px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    @media all and (max-width: 1024px) {
      top: 248px;
    }
    @media all and (max-width: 767px) {
      top: 144px;
    }

    .join_l_wrapper {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #EB001B;
        z-index: -1;
        top: 0;
        border-radius: 50%;
        background: rgba(235, 0, 27, 0.80);
        filter: blur(250px);
      }

      .join_l {
        max-width: 620px;
        width: 100%;
        display: block;
        height: auto;

        @media all and (max-width: 1300px) {
          max-width: 400px;
        }
      }
    }

    .join_r_wrapper {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #EB001B;
        z-index: -1;
        top: 0;
        border-radius: 50%;
        background: rgba(235, 0, 27, 0.80);

        filter: blur(250px);
      }

      .join_r {
        max-width: 620px;
        width: 100%;
        display: block;
        height: auto;
        @media all and (max-width: 1300px) {
          max-width: 400px;
        }
      }
    }
  }


  &_title_wrapper {
    .join_title {
      color: $white_text_color;
      max-width: 730px;
      margin: 0 auto 120px;
      text-align: center;
      font-family: $Plex-400;
      font-size: 64px;
      font-style: normal;
      font-weight: 400;
      line-height: 64px; /* 100% */
      text-transform: uppercase;
      @media all and (max-width: 1024px) {
        font-size: 48px;
        max-width: 464px;
        margin: 0 auto 80px;
      }
      @media all and (max-width: 767px) {
        font-size: 36px;
        line-height: 36px;
        margin: 0 auto 100px;
        //max-width: 464px;
        //margin: 0 auto 80px;
      }
    }
  }

  &_screens {
    display: flex;
    position: relative;
    border-bottom: 2px solid #fff;

    .join_chat {
      max-width: 451px;
      width: 100%;
      height: auto;
      position: relative;
      z-index: 2;
      margin-left: 66%;
      display: block;
      @media all and (max-width: 1300px) {
        margin-left: 52%;
      }
      @media all and (max-width: 1200px) {
        margin-left: 42%;
      }
      @media all and (max-width: 1024px) {
        max-width: 257px;
        margin-left: 74%;
      }
      @media all and (max-width: 767px) {
        max-width: 196px;
        margin-left: 85%;
      }

      @media all and (max-width: 576px) {
        margin-left: 44%;
      }

      @media all and (max-width: 420px) {
        margin-left: 32px;
      }
    }

    .join_splash {
      position: absolute;
      bottom: 0;
      z-index: 1;
      left: 48%;
      max-width: 400px;
      width: 100%;
      height: auto;
      display: block;
      box-sizing: border-box;
      @media all and (max-width: 1024px) {
        max-width: 217px;
      }
      @media all and (max-width: 767px) {
        max-width: 165px;
      }
    }
  }
}