.social_fi {
  padding: 200px 0 0;
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;

  @media all and (max-width: 1024px) {
    padding: 80px 0 0;
  }

  .social_fi_media {
    top: 400px;
    right: 0;
    width: 50%;
    display: flex;
    position: absolute;
    height: 371px;
    justify-content: flex-end;
    @media all and (max-width: 1024px) {
      height: 170px;
      top: 190px;
    }

    @media all and (max-width: 767px) {
      position: static;
      width: 100%;
      height: auto;
      margin: 0 0 0 16px;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      @media all and (max-width: 767px) {
        //margin-right: -16px;
      }
    }
  }

  &_title {
    max-width: 730px;
    color: $white_text_color;
    font-family: $Plex-400;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 64px; /* 100% */
    text-transform: uppercase;
    margin: 0 0 80px;
    @media all and (max-width: 1024px) {
      max-width: 400px;
      font-size: 36px;
      line-height: 36px;
      margin: 0 0 36px;
    }
  }

  &_content {
    position: relative;
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 767px) {
      flex-direction: column;
    }

    .social_fi_info {
      width: 50%;
      max-width: 530px;
      padding-right: 60px;
      box-sizing: border-box;
      @media all and (max-width: 767px) {
        padding: 0;
        margin-bottom: 80px;
        width: 100%;
      }

      &_text {
        color: $white_text_color;
        font-family: $Plex-400;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 155.556% */
        margin: 0 0 56px;
        @media all and (max-width: 1024px) {
          font-size: 16px;
          line-height: 20px;
          margin: 0 0 40px;
        }
      }

      &_cta {
        display: flex;
        align-items: center;
        @media all and (max-width: 767px) {
          justify-content: center;
        }

        .buy_now {
          text-decoration: none;
          margin-right: 40px;
          @media all and (max-width: 576px) {
            margin-right: 12px;
          }
        }

        .wp {
          color: $white_text_color;
          font-family: $Plex-700;
          font-size: 20px;
          font-style: normal;
          line-height: 28px; /* 140% */
          text-decoration-line: underline;
          white-space: nowrap;
          @media all and (max-width: 1024px) {
            font-size: 16px;
          }
        }
      }
    }

    .social_fi_empty {
      width: 50%;
      height: 371px;
      position: relative;
      @media all and (max-width: 767px) {
        height: 1px;
        width: 100%;
      }

      &:after {
        content: '';
        width: 446px;
        height: 446px;
        border-radius: 466px;
        background: rgba(235, 0, 27, 0.40);
        filter: blur(200px);
        position: absolute;
        left: 40%;
        z-index: -1;
        top: -180px;
        @media all and (max-width: 1024px) {
          width: 260px;
          height: 260px;
          filter: blur(100px);
          top: -20px;
        }
        @media all and (max-width: 767px) {
          width: 180px;
          height: 180px;
          filter: blur(100px);
          top: -130px;
        }
      }
    }

  }
}