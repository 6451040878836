.tokenomics {
  padding: 260px 0 0;
  overflow: hidden;
  box-sizing: border-box;

  @media all and (max-width: 1024px) {
    padding: 100px 0 0;
  }

  &_title {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 0 98px;
    @media all and (max-width: 767px) {
      margin: 0 0 68px;
    }
  }

  &_data {
    display: flex;
    align-items: flex-start;
    @media all and (max-width: 767px) {
      flex-direction: column;
    }

    .data_info {
      flex: 2;

      @media all and (max-width: 1200px) {
        width: 40%;
        flex: inherit;
      }
      @media all and (max-width: 767px) {
        width: 100%;
        order: 2;
      }

      &_item {
        margin-bottom: 48px;
        @media all and (max-width: 1024px) {
          margin-bottom: 24px;
        }

        &:first-child {
          padding-bottom: 52px;
          margin-bottom: 78px;
          position: relative;
          @media all and (max-width: 1024px) {
            padding-bottom: 30px;
            margin-bottom: 66px;
          }

          @media all and (max-width: 767px) {
            padding-bottom: 24px;
            margin-bottom: 24px;
          }

          .data_info_item_value {
            font-size: 112px;
            line-height: 96px;
            @media all and (max-width: 1200px) {
              font-size: 80px;
            }
            @media all and (max-width: 1024px) {
              font-size: 58px;
              line-height: 36px;
              margin: 0 0 20px;
            }
          }

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            height: 2px;
            background: #fff;
            width: 100vw;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        &_value {
          color: $white_text_color;
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: $Cute;
          font-size: 72px;
          font-style: normal;
          font-weight: 400;
          line-height: 62px; /* 85.714% */
          letter-spacing: -1px;
          margin: 0;
          @media all and (max-width: 1200px) {
            font-size: 60px;
          }
          @media all and (max-width: 1024px) {
            font-size: 36px;
            line-height: 36px;
          }
        }

        &_title {
          color: $white_text_color_70;
          font-family: $Plex-400;
          font-size: 21px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px; /* 133.333% */
          text-transform: uppercase;
          margin: 0;
          @media all and (max-width: 1024px) {
            font-size: 16px;
            line-height: 16px;
          }
        }
      }
    }

    .data_umberto {
      position: relative;
      margin: 0 50px 0;
      @media all and (max-width: 1024px) {
        margin: 0 22px 0;
      }
      @media all and (max-width: 767px) {
        order: 1;
        margin: 0 0 34px;
      }

      &:after {
        position: absolute;
        top: 0;
        right: -100%;
        z-index: -1;
        content: '';
        width: 216px;
        height: 216px;
        background: rgba(235, 0, 27, 0.70);
        filter: blur(100px);
      }

      &_wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .umberto_img {
          max-width: 144px;
          width: 100%;
          height: auto;
          @media all and (max-width: 1024px) {
            max-width: 108px;
          }
        }

        .umberto_coin {
          height: 700px;
          @media all and (max-width: 1024px) {
            height: 500px;
          }
          @media all and (max-width: 767px) {
            display: none;
          }
        }
      }

      &_tooltip {
        position: absolute;
        top: -30px;
        right: -240%;
        @media all and (max-width: 1200px) {
          right: -360px;
        }

        @media all and (max-width: 1024px) {
          right: -300px;
          width: 240px;
        }

        @media all and (max-width: 767px) {
          width: 240px;
        }
        @media all and (max-width: 576px) {
          width: 210px;
          right: -240px;
        }


        .tooltip {
          color: #fff;
          max-width: 290px;

          h4 {
            font-family: $Plex-600;
            font-size: 16px;
            margin: 0 0 6px;
            @media all and (max-width: 1024px) {
              font-size: 12px;
            }
          }

          p {
            margin: 0;
            font-size: 14px;
            font-family: $Plex-300;
            @media all and (max-width: 1024px) {
              font-size: 12px;
            }
          }
        }

        .tooltip {
          --b: 3em; /* tail dimension */

          --p: 50%; /* main position (0%:top 100%:bottom) */
          --r: 1.2em; /* the radius */

          padding: 18px 36px;
          box-sizing: border-box;
          border-radius: 24px;
          border: 1px solid #FFFFFF;
          background: #17151e; /* the main color */
          position: relative;
          @media all and (max-width: 576px) {
            padding: 12px;
          }
        }

        .tooltip:before {
          content: "";
          position: absolute;
          right: 100%;
          top: clamp(0%, var(--p) - var(--b) / 4, 100% - var(--b) / 2);
          width: var(--b);
          aspect-ratio: 1;
          background: #fff;
          --g: #000 calc(100% - 1px), #0000;
          -webkit-mask: radial-gradient(circle closest-side at 12% 88%, var(--g)),
          radial-gradient(20% 20% at 45% 48%, var(--g)),
          radial-gradient(25% 25% at 100% 25%, var(--g));
        }
      }

    }

    .data_chart {
      padding-top: 180px;
      box-sizing: border-box;
      flex: 2;
      @media all and (max-width: 1200px) {
        width: 50%;
        flex: inherit;
      }

      @media all and (max-width: 767px) {
        order: 3;
        width: 100%;
        padding-top: 40px;
      }


      &_wrapper {
        margin: 0 -2px 40px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;

        @media all and (max-width: 1024px) {
          margin: 0 -2px 30px;
        }
        @media all and (max-width: 767px) {
          margin: 0 -8px 30px;
        }


        .data_chart_item {
          width: 10%;
          padding: 0 2px 10px;
          display: flex;
          box-sizing: border-box;
          @media all and (max-width: 767px) {
            justify-content: center;
          }

          svg {
            @media all and (max-width: 1024px) {
              width: 20px;
              height: auto;
            }
          }
        }
      }

      &_legend {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;

        .legend_item {
          width: 33%;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          padding: 0 5px;
          box-sizing: border-box;
          @media all and (max-width: 1024px) {
            align-items: initial;
          }

          .circle {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            margin-right: 12px;
          }

          .data {
            @media all and (max-width: 1024px) {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }
            h4 {
              color: $white_text_color_50;
              margin: 0 0 10px;
              font-feature-settings: 'liga' off, 'clig' off;
              font-family: $Arial-400;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 171.429% */
              @media all and (max-width: 1024px) {
                font-size: 12px;
                line-height: 14px;
                margin: 0 0 4px;
              }
            }

            p {
              color: #FFF;
              font-family: $Cute;
              font-size: 48px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 50% */
              margin: 0;
              @media all and (max-width: 1024px) {
                font-size: 36px;
              }
            }
          }

        }
      }
    }
  }

}