.audit {
  padding: 234px 0 100px;
  box-sizing: border-box;
  overflow: hidden;
  @media all and (max-width: 1024px) {
    padding: 120px 0 100px;
  }

  &_data {
    display: flex;
    border-radius: 91px;
    border: 2px solid #FFF;
    padding: 22px 68px;
    box-sizing: border-box;
    max-width: 920px;
    margin: 0 auto;
    position: relative;
    @media all and (max-width: 1024px) {
      padding: 14px 44px;
    }
    @media all and (max-width: 576px){
      padding: 19px 44px;
    }

    &:before, &:after {
      position: absolute;
      content: '';
      top: 50%;
      transform: translateY(-50%);
      height: 2px;
      background: #fff;
      width: 100vw;
    }

    &:before {
      left: -100vw;
    }

    &:after {
      right: -100vw;
    }

    &_l {
      flex: 1;
      display: flex;
      align-items: center;
      @media all and (max-width: 576px){
        display: none;
      }

      img {
        width: 36px;
        height: auto;
        margin-right: 20px;
        @media all and (max-width: 1024px) {
          width: 24px;
        }
      }

      a {
        color: $white_text_color;
        font-family: $Plex-500;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 77.778% */
        text-decoration-line: underline;
        text-transform: uppercase;
        @media all and (max-width: 1024px) {
          font-size: 24px;
        }
      }
    }

    &_c {
      position: relative;
      @media all and (max-width: 576px){
        flex: 1;
      }

      img {
        position: absolute;
        height: auto;
        display: block;
        max-width: 200px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @media all and (max-width: 1024px) {
          max-width: 114px;
        }
      }
    }

    &_r {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @media all and (max-width: 576px){
        display: none;
      }

      a {
        color: $white_text_color;
        font-family: $Plex-500;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 77.778% */
        text-decoration-line: underline;
        text-transform: uppercase;
        @media all and (max-width: 1024px) {
          font-size: 24px;
        }
      }

      img {
        width: 31px;
        height: auto;
        margin-left: 20px;
        @media all and (max-width: 1024px) {
          width: 21px;
        }
      }
    }
  }
  .mobile_links {
    display: none;
    @media all and (max-width: 576px){
      display: flex;
      flex-direction: column;
      padding: 60px 0 0;
      align-items: center;
    }

    .audit_data_m {
      display: flex;
      align-items: center;
      &:first-child {
        margin-bottom: 20px;
      }
      img {
        width: 36px;
        height: auto;
        margin-right: 20px;
        @media all and (max-width: 1024px) {
          width: 24px;
        }
      }

      a {
        color: $white_text_color;
        font-family: $Plex-500;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 77.778% */
        text-decoration-line: underline;
        text-transform: uppercase;
        @media all and (max-width: 1024px) {
          font-size: 24px;
        }
      }
    }
  }
}