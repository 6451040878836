@import "variables";

.footer {
  padding: 80px 0 125px;
  @media all and (max-width: 1024px) {
    padding: 0 0 30px;
  }

  &_disclaimer {
    margin: 0 0 56px;
    color: $black_text_color_50;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: $Arial-400;
    letter-spacing: 0.5px;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.5px;
    @media all and (max-width: 767px) {
      margin: 0 0 40px;
    }
  }

  &_top {
    padding: 0 0 56px;
    border-bottom: 2px solid #fff;
    box-sizing: border-box;
    margin-bottom: 56px;

    @media all and (max-width: 1024px) {
      padding: 0 0 30px;
      margin-bottom: 30px;
    }
    @media all and (max-width: 767px) {
      flex-direction: column;
    }

    .nav_logo {
      box-sizing: border-box;
      img {
        width: 170px;
        @media all and (max-width: 1024px) {
          width: 127px;
        }
      }
    }
  }

  &_main {
    display: flex;
    margin: 0 -16px 64px;
    @media all and (max-width: 1024px) {
      flex-wrap: wrap;
    }
    @media all and (max-width: 767px) {
      margin: 0 -16px 30px;
    }

    &_item {
      padding: 0 16px;
      box-sizing: border-box;
      flex: 1;
      @media all and (max-width: 767px) {
        width: 50%;
        flex: none;
        margin-bottom: 30px;
        &:nth-child(3) {
          margin-bottom: 0;
        }
        &:nth-child(4) {
          margin-bottom: 0;
        }
      }

      &_list {
        .list_item {
          color: $white_text_color;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: $Plex-400;
          letter-spacing: 0.6px;
          font-weight: 400;
          font-size: 14px;
          line-height: 28px;
          text-decoration: none;
          @media all and (max-width: 1024px) {
            font-size: 12px;
            line-height: 24px;
          }
          transition: all 0.3s ease-in-out;
          &:hover {
            color: $red_color;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
  }

  &_bottom {
    display: flex;
    justify-content: space-between;
    margin: 0 -50px;
    @media all and (max-width: 1024px) {
      margin: 0 -20px;
    }
    @media all and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
    }

    &_copyright {
      color: $white_text_color_50;
      font-family: $Plex-400;
      letter-spacing: 0.6px;
      font-weight: 400;
      font-size: 11px;
      line-height: normal;
      padding: 0 50px;
      margin: 0;
      box-sizing: border-box;
      @media all and (max-width: 1024px) {
        padding: 0 20px;
        font-size: 10px;
        max-width: 194px;
        flex: 1;
      }
      @media all and (max-width: 767px) {
        max-width: none;
        margin: 0 0 40px;
      }
    }

    &_disclaimer {
      color: $white_text_color_50;
      font-family: $Plex-400;
      font-size: 11px;
      margin: 0;
      padding: 0 50px;
      box-sizing: border-box;
      @media all and (max-width: 1024px) {
        padding: 0 20px;
        flex: 2;
      }
      @media all and (max-width: 1024px) {
        margin: 0 0 40px;
        text-align: center;
      }
    }

    &_social {
      display: flex;
      padding: 0 50px;
      @media all and (max-width: 1024px) {
        padding: 0 20px;
        justify-content: flex-end;
      }


      &_item {
        margin-right: 40px;
        @media all and (max-width: 1024px) {
          margin-right: 14px;
        }
        svg {
          transition: all 0.3s ease-in-out;
        }
        &:hover {
          svg {
            path {
              transition: all 0.3s ease-in-out;
              fill: $red_color;
            }
          }
        }


        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}